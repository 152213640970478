import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { environment } from '@environments/environment';
import { RsSentry } from '@lib/utils/rs-senty-init';

new RsSentry({
  dsn: 'https://280ba090dedc4de0a3ae9cd422f2925c@sentry.io/1806729',
  enabled: environment.enableSentryMonitoring,
  environment: environment.environment,
  sentryReleaseName: environment.sentryReleaseName
}).sentryInit();

Sentry.getCurrentScope().setTag('app.environment', environment.environment);


Sentry.getCurrentScope().setTag('app.name', 'FMS');

@Injectable()
export class SentryErrorHandler implements ErrorHandler {

  public handleError(error: { originalError: unknown; }): void {
    Sentry.captureException(error.originalError || error);
  }

}
