import { Component, DestroyRef, inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';

import { environment } from '@src/environments/environment';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import introJs from 'intro.js';
import { MenuItem } from '@rs-shared-lib/models';
import { RsTopBarMenuComponent } from '@rs-shared-lib/components/topbar/rs-top-bar-menu.component';
import { RsLoaderComponent } from '@rs-shared-lib/components/rs-loader/components/rs-loader.component';
import { RsFooterComponent } from '@rs-shared-lib/components/footer/rs-footer.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule, ViewportScroller } from '@angular/common';
import { UserService } from '@shared/services/user/user.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

interface Translations {
  'INTROJS_TUTORIAL.REGISTRATION_MENU_ITEM.TITLE': string,
  'INTROJS_TUTORIAL.REGISTRATION_MENU_ITEM.PARAGRAPH_1': string,
  'INTROJS_TUTORIAL.REGISTRATION_MENU_ITEM.PARAGRAPH_2': string,
  'INTROJS_TUTORIAL.FINES_MENU_ITEM.TITLE': string,
  'INTROJS_TUTORIAL.FINES_MENU_ITEM.PARAGRAPH_1': string,
  'INTROJS_TUTORIAL.FINES_MENU_ITEM.PARAGRAPH_2': string,
  'INTROJS_TUTORIAL.DASHBOARD_MENU_ITEM.TITLE': string,
  'INTROJS_TUTORIAL.DASHBOARD_MENU_ITEM.PARAGRAPH_1': string,
  'INTROJS_TUTORIAL.DASHBOARD_MENU_ITEM.PARAGRAPH_2': string,
  'INTROJS_TUTORIAL.NEXT': string,
  'INTROJS_TUTORIAL.BACK': string,
  'INTROJS_TUTORIAL.FINISH': string
}

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
  imports: [
    CommonModule,
    RsTopBarMenuComponent,
    RouterOutlet,
    RsLoaderComponent,
    RsFooterComponent,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    TranslateModule,
  ]
})
export class AppComponent implements OnInit {

	@ViewChild('introJsDialog', { read: TemplateRef }) public introJsDialog!: TemplateRef<MatDialog>;
	protected topBarEnvironment = environment.topBarEnvironment;
	protected topBarCdn?: string;
	protected menuItems: MenuItem[] = [];
	private introJs = introJs;
	private translations!: Translations;
	private destroyRef$ = inject(DestroyRef);
	private introJsDialogRef?: MatDialogRef<MatDialog>;

	constructor(
		private readonly router: Router,
		private readonly translationService: TranslateService,
		private readonly dialog: MatDialog,
		private readonly viewport: ViewportScroller,
		private readonly userService: UserService
	) {
	  this.viewport.setOffset([0, 64]);
	}

	public ngOnInit(): void {
	  this.topBarCdn = environment.topBarCdn;
	  this.setMenuItems();
	  this.getTranslations();
	}

	public isFirstTimeUserConnects(): void {
	  this.userService.getUserInfo()
	    .subscribe({
	      next: (userInfo) => {
	        if (userInfo?.firstTime) {
	          this.acceptTutorialDialog();
	        }
	      }
	    });
	}

	public acceptTutorialDialog(): void {
	  this.introJsDialogRef = this.dialog
	    .open(this.introJsDialog, {
	      width: '500px',
	      autoFocus: false,
	      panelClass: 'dialog-intro-js'
	    });
	}

	public startTutorial(): void {
	  this.router.navigate(['registrations']).then();

	  this.introJs()
	    .setOptions({
	      steps: [
	        {
	          tooltipClass: 'step-1',
	          element: document.querySelector('[data-menuitem=registration]')!,
	          intro: `
              <div class="text-center">
                <h2>${this.translations!['INTROJS_TUTORIAL.REGISTRATION_MENU_ITEM.TITLE']}</h2>
                <p class="mb-15px">${this.translations!['INTROJS_TUTORIAL.REGISTRATION_MENU_ITEM.PARAGRAPH_1']}</p>
                <p>${this.translations!['INTROJS_TUTORIAL.REGISTRATION_MENU_ITEM.PARAGRAPH_2']}</p>
              </div>
            `
	        },
	        {
	          element: document.querySelector('[data-menuitem=fines]')!,
	          intro: `
              <div class="text-center">
                <h2>${this.translations!['INTROJS_TUTORIAL.FINES_MENU_ITEM.TITLE']}</h2>
                <p class="mb-15px"> ${this.translations!['INTROJS_TUTORIAL.FINES_MENU_ITEM.PARAGRAPH_1']} </p>
                <p>${this.translations!['INTROJS_TUTORIAL.FINES_MENU_ITEM.PARAGRAPH_2']}</p>
              </div>
            `
	        },
	        {
	          element: document.querySelector('[data-menuitem=dashboard]')!,
	          intro: `
              <div class="text-center">
                <h2>${this.translations!['INTROJS_TUTORIAL.DASHBOARD_MENU_ITEM.TITLE']}</h2>
                <p class="mb-15px">${this.translations!['INTROJS_TUTORIAL.DASHBOARD_MENU_ITEM.PARAGRAPH_1']}</p>
                <p>${this.translations!['INTROJS_TUTORIAL.DASHBOARD_MENU_ITEM.PARAGRAPH_2']}</p>
              </div>
            `
	        }
	      ],
	      doneLabel: this.translations!['INTROJS_TUTORIAL.FINISH']
	    })
	    .start()
	    .onbeforechange((htmlElement) => {
	      const id = (htmlElement as HTMLLinkElement).getAttribute('data-menuitem');

	      switch (id) {
	        case 'fines':
	          this.router.navigate(['fines']).then();
	          break;

	        case 'registration':
	          this.router.navigate(['registrations']).then();
	          break;

	        case 'dashboard':
	          this.router.navigate(['dashboard']).then();
	          break;
	      }
	    })
	    .onexit(() => {
	      this.router.navigate(['registrations']).then();
	    });
	}

	private getTranslations(): void {
	  this.translationService
	    .stream([
	      'INTROJS_TUTORIAL.REGISTRATION_MENU_ITEM.TITLE',
	      'INTROJS_TUTORIAL.REGISTRATION_MENU_ITEM.PARAGRAPH_1',
	      'INTROJS_TUTORIAL.REGISTRATION_MENU_ITEM.PARAGRAPH_2',
	      'INTROJS_TUTORIAL.FINES_MENU_ITEM.TITLE',
	      'INTROJS_TUTORIAL.FINES_MENU_ITEM.PARAGRAPH_1',
	      'INTROJS_TUTORIAL.FINES_MENU_ITEM.PARAGRAPH_2',
	      'INTROJS_TUTORIAL.DASHBOARD_MENU_ITEM.TITLE',
	      'INTROJS_TUTORIAL.DASHBOARD_MENU_ITEM.PARAGRAPH_1',
	      'INTROJS_TUTORIAL.DASHBOARD_MENU_ITEM.PARAGRAPH_2',
	      'INTROJS_TUTORIAL.NEXT',
	      'INTROJS_TUTORIAL.BACK',
	      'INTROJS_TUTORIAL.FINISH',
	      'YES',
	      'NO'
	    ])
		  .pipe(takeUntilDestroyed(this.destroyRef$))
	    .subscribe((translation): void => {
	      this.translations = translation;
	    });
	}

	private setMenuItems(): void {
	  this.menuItems = [
	    {
	      url: '/registrations',
	      label: 'REGISTRATIONS.REGISTRATIONS',
	      id: 'registration'
	    },
	    {
	      url: '/fines',
	      id: 'fines',
	      label: 'REQUESTS.FINES'
	    },
	    {
	      url: '/dashboard',
	      id: 'dashboard',
	      label: 'DASHBOARD.DASHBOARD'
	    },
	    {
	      url: '/scanning',
	      id: 'scanning',
	      label: 'SCANNING.TITLE'
	    }
	  ];
	}
}
